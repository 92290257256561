import React from "react"
import {graphql} from "gatsby"
import {Col, Container, Row} from "react-bootstrap"
import {IPage} from "../types";
import BouwtypeList from "../components/Woningtypes";
import {StaticImage} from "gatsby-plugin-image";
import Situatie from "../components/Situatie";
import Page from "gatsby-theme-nieuwbouw/src/components/Page";


interface Props {
    data: PageQueryData
}
export default ({ data }: Props) => {
    return (
        <Page pageTitle={data.file.childMarkdownRemark.frontmatter.title} pageDescription={data.file.childMarkdownRemark.frontmatter.description}>
            <StaticImage
                src={'../img/woningen/GettyImages-1141119284@2x.png'}
                aspectRatio={1440/407}
                layout={'fullWidth'}
                alt={'cheers'}
            />
            <Container
                fluid={'lg'}
                className={'my-5 text-center text-md-left'}
            >
                <div className={'my-5'}>
            <span className={'title text-uppercase font-weight-bold'}>
                            Vaartsche Weelde
            </span>
                    <h2>
                        <span className={'text-primary'}>Uniek</span> wonen
                    </h2>
                </div>
                <p className={'lead my-5'} style={{
                    maxWidth: 768
                }}>
                    De reflectie van de zon in het water. Het horen van getik van een touw tegen een zeilbootmast in de verte. Welkom in Vaartsche Weelde. De locatie van dit project is met recht uniek te noemen.
                </p>
                <Row className={'my-5'}>
                    <Col md={{span: 7, offset: 1}} className={'border-left'}>
                        <p className={'mb-0'}>
                            Vanuit de sluis springen de 17 kadewoningen direct in het oog. Grote volumes direct aan vaarwater gelegen met vrij uitzicht, een privéplek voor de boot en een eigen vingersteiger. Dit voelt als heerlijk thuiskomen.
                        </p>
                    </Col>
                </Row>
                <Row className={'my-5'}>
                    <Col md={{span: 7, offset: 1}}>
                        <h6>Onderdeel van Kokkelhof </h6>
                        <p>
                            In de achterliggende bebouwing wordt gelijktijdig project Binnengaats ontwikkeld. Dit is een appartementengebouw, bestaande uit drie lagen en wordt volledig in stijl gebouwd van Vaartsche Weelde. Deze twee losse projecten zijn samen gesitueerd aan een groen plein: het Kokkelhof.
                        </p>
                        <p>
                            Rond dit plein komen buurtgenoten samen, spelen de kinderen en kan geparkeerd worden. Nog mooier: hier staan twee elektrische deelauto’s, twee elektrische deelscooters en een elektrische deelbakfiets waar u als bewoner altijd gebruik van kunt maken. Handig voor een snelle boodschap of om de kinderen op te halen.
                        </p>
                        <p className={'font-weight-bold'}>
                            De 17 kadewoningen in Vaartsche Weelde zijn onderverdeeld in drie typen:
                        </p>
                    </Col>
                </Row>
                <section
                    className={'my-5'}
                >
                    <BouwtypeList woningen={true} />
                </section>
                <section
                    className={'my-5'}
                >
                    <header>
                        <h2 className={'text-primary'}>
                            Situatie Vaartsche Weelde
                        </h2>
                    </header>
                    <Situatie />
                </section>
            </Container>
        </Page>
    );
}

interface PageQueryData {
    file: {
        childMarkdownRemark: IPage
    }
}

export const pageQuery = graphql`
    query {
        file(name: {eq: "woningen"}) {
            childMarkdownRemark {
                ...Page
            }
        }
    }
`

